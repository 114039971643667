import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import App from './App.js';
import './css/index.css';
import Pfpfp from './pfpfp.js';

ReactDOM.createRoot(document.getElementById("root")).render(
	<BrowserRouter>
		<Routes>
			<Route path="/turboplebs" element={<Pfpfp />}> </Route>
			<Route path="" element={<App/>} />
		</Routes>
	</BrowserRouter>
);