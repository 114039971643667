import logo from "./img/textlogo.png";

function App() {
    return(
        <div className="appmain">
            <img src={logo} />
        </div>
    )
}

export default App;