import { useState } from "react"
import preview from "./img/pfpfp.png"
import logo from "./img/superthereum.png"

function Pfpfp() {

	const [userAccount, setUserAccount] = useState(null)

	const [hasWL, setHasWL] = useState(null)
	

	const checkWL = async () => {
		try {
            let data = await fetch('https://superthereum.io/wl?addr=' + userAccount, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
            })

			if (data.status == 200) {
				setHasWL(true)
			}else {
				setHasWL(false)
			}
        } catch (error) {
			console.log(error)
        }
	}

	const setAddress = (e) => {
		setUserAccount(e.target.value)
		setHasWL(null)
	}

	return (
		<div className="app">
			<div className="toolbar">
				<a href="/"><img src={logo} className="title" /></a>
				<div className="menu">
					<a href="https://twitter.com/superthereum" target="_blank">Twitter</a>
					<a href="https://discord.com/invite/gQ9rxdzFfn" target="_blank">Discord</a>
					<a href="#" className="hideonmobile">Buy Secondary</a>
					<a href="#" className="hideonbigscreen">Buy</a>
				</div>
			</div>

			<div className="container">
				<div className="left"></div>
				<div className="left2">
					<div className="walletchecker">
						<div className="title">Check Superlist Status</div>
						<input type="text" placeholder="Paste ETH address here" value={userAccount} onChange={setAddress} />

						<div className="check" onClick={checkWL}>Check</div>
						{ 
							hasWL == true ? 
							<div className="result" style={{color: "green"}}>You are on the Superlist!</div> 
							: 
							hasWL == false ? 
							<div className="result" style={{color: "red"}}>You are not on the Superlist!</div> 
							: 
							<div></div>
						}
					</div>
					<div className="metapreview">
						<img src={preview} />
					</div>
				</div>
				<div className="main">
						<div className="title">EXPERIMENT 0: TURBO PLEBS</div>
						<div className="subtitle">420 Maximum Supply</div>
						<div className="subtitle">Expect less. Experiment more.</div>
					</div>
			</div>
			<div className="footer">
				<div className="left">
					
				</div>
				<div className="content">&copy; 2024 SUPERTHEREUM </div>
				
			</div>

		</div>
	);
}

export default Pfpfp;
